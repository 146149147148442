body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  

  display: grid;
 /* grid-template-columns: 20% 80%;*/
 grid-template-areas:
 'header header' 
 'leftNav main'
 'footer footer';
}

.Header {
  grid-area: header;
  height: 7vh;
  background-color: rgba(33, 33, 33, 0.98);
  line-height: 7vh;
  display: flex;
  align-items: center;
}

@font-face {
  font-family: 'Kenyan-Coffee';
  src:url('../fonts/Kenyan-Coffee.ttf.woff') format('woff'),
      url('../fonts/Kenyan-Coffee.ttf.svg#Kenyan-Coffee') format('svg'),
      url('../fonts/Kenyan-Coffee.ttf.eot'),
      url('../fonts/Kenyan-Coffee.ttf.eot?#iefix') format('embedded-opentype'); 
  font-weight: normal;
  font-style: normal;
}

.YT_button{
  font-family: 'Kenyan-Coffee';
  display: inline;
  color: white;
  text-decoration: none;
  font-size: 150%;
  margin-right: 5rem;
  vertical-align: middle;
  text-align: center;

}

.YT_icon {

  padding-right: 0.1rem;
}

.search_input {
  margin-left: 1.5rem;
  background-color: black;
  color:rgba(33, 33, 33, 0.98);
  border: none;
  margin: 0px;
  width: 100%;
  color: white;
  padding-left: 1em;
}

#search_btn {
  height: 70%;
  background-color: black;
  width: 4rem;
  color: white;
}

#search_div {
  background-color: black;
  width: 30rem;
  height: 70%;
  display: flex;
  border: 1px solid hsl(0, 0%, 19%);
  border-right: none;
}

.Footer {
  grid-area: footer;
  height: 7vh;
  background-color: rgba(33, 33, 33, 0.98);
  color: white;
}

.Left {
  grid-area: leftNav;
  width: 15vw;
  background-color: #212121;
}

.Main {
  height: 86vh;
  grid-area: main;
  width: 85vw;
}

